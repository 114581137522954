import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import child_planting from "../../images/farm-business/bulk-cof-compost/child-planting.jpg"
import farm_equipment from "../../images/farm-business/bulk-cof-compost/farm-equipment.jpg"
import composition_test from "../../images/farm-business/bulk-cof-compost/composition-test.jpg"
import bulk_storage from "../../images/farm-business/bulk-cof-compost/bulk-storage.jpg"

const BulkCofCompost = () => (
  <Layout>
    <SEO title="Bulk / COF / Compost" />
    <br />
    <h1>Bulk / COF / Compost</h1>
    <div>
      <p><strong>Hours: 10-5 Tuesday-Saturday / Manure by Appointment 9:30 Tuesday, Wednesday, Saturday</strong></p>
      <p>The success of any garden, farm, or lawn begins with the quality of its soil. Our role is to help best feed
            your soil so the soil can feed your plants.</p>
      <p>Soil is not an organism by itself, but it has rich and manifold life in it. Organic fertilizers and soil
      amendments encourage this life in micro organisms and plant roots by promoting good soil structure through a
      wide variety of easily assimilated primary nutrients, trace minerals and organic matter. This enlivened soil
      structure will benefit tilth and allow for better aeration, water absorption and the unlocking of nutrients
            that stimulate plant and root development.</p>
      <p>Remember, healthy soil produces healthy plants</p>
      <p>We offer: Meals, Compost, Organic Amendments, Mycorrhizal, Lime, Calcium, Gypsum, Minerals and much more.</p>

      <div class="row mb-3">
        <div class="col-sm text-center">
          <img alt="Child gardening at Baxter Barn" style={{height: `243px`}} src={child_planting}></img>
        </div>
        <div class="col-sm text-center">
          <img alt="Farm equipment at Baxter Barn" src={farm_equipment}></img>
        </div>
      </div>

      <h4>Bulk Premix</h4>
      <p>Original and New: Steve Solomon uses what he calls <strong>'complete' organic</strong> fertilizer - a mixture
            to prevent micro-nutrient deficiencies: <a href="http://www.soilandhealth.org/index.html"
          title="http://www.soilandhealth.org/index.html">http://www.soilandhealth.org/index.html</a></p>
      <h4>Northwest Urban Gardner</h4>
      <p>Our Natural Manure Blend $25 Fresh or aged (2-5 months) $45 per pickup load:</p>
      <p>The use of our manure compost blend of poultry, horse, rabbit, donkey, is a great blend and can provide
      numerous benefits to the garden. Its high in organic materials and rich in nutrients and a great source of
            nitrogen, phosphorus, and potassium.</p>
      <p>Manure is organic matter used as organic fertilizer in agriculture. Manures contribute to the fertility of
      the soil by adding organic matter and nutrients, such as nitrogen, that are trapped by bacteria in the soil.
      Higher organisms then feed on the fungi and bacteria in a chain of life that comprises the soil food web. It
      is also a product obtained after decomposition of organic matter like horse-dung which replenishes the soil
            with essential elements and add humus to the soil.</p>
      <p>When it comes to manures, there is none more desired for the vegetable garden than chicken manure. Chicken
      manure for vegetable garden fertilizing is excellent, but there are some things you need to know about it in
      order to use it correctly. Keep reading to learn more about chicken manure compost and how to use it in the
            garden.</p>
      <p>If you’re looking for a good organic fertilizer for the garden, then you might want to consider using rabbit
            manure. Garden plants respond well to this type of fertilizer, especially when it’s been composted.</p>
      <p>The chicken manure gives a great start and can last a month or less but the other blend of manure added tends
            to add longer beneficial facts to the soil.</p>
      <p>N-P-K ratio 14-9-12</p>
      <p><strong>(N)</strong> Nitrogen helps plants make the proteins they need to produce new tissues. In nature,
            nitrogen is often in short supply so plants have evolved to take up as much nitrogen as possible, even if it
            means not taking up other necessary elements. If too much nitrogen is available, the plant may grow abundant
            foliage but not produce fruit or flowers. Growth may actually be stunted because the plant isn't absorbing
            enough of the other elements it needs.</p>
      <p><strong>(P)</strong> Potassium improves overall vigor of the plant. It helps the plants make carbohydrates
            and provides disease resistance. It also helps regulate metabolic activities.</p>
      <p><strong>(K)</strong> Calcium is used by plants in cell membranes, at their growing points and to neutralize
            toxic materials. In addition, calcium improves soil structure and helps bind organic and inorganic particles
            together.</p>
      <h4><strong>ALSO FOR SALE IN BULK:</strong></h4>
      <ul>
        <li>MEALS (SOIL AMENDMENTS-CONCENTRATES)</li>
        <li>ORG ALALFA MEAL</li>
        <li>COTTONSEED MEAL</li>
        <li>KELP MEAL TIDAL*</li>
        <li>SOYBEAN MEAL*</li>
        <li>FISH BONE MEAL SCOUT 4-20</li>
        <li>BONE MEAL 3-15-1 GROUND</li>
        <li>BLOOD MEAL</li>
        <li>CRUSTACEAN MEAL</li>
        <li>FEATHER MEAL GROUND</li>
        <li>FISH MEAL OG PROPELL</li>
        <li>SOILS-COMPOST-PEAT</li>
        <li>NATURAL AND ORGANIC POTTING SOIL</li>
        <li>ALASKA PEAT MOSS</li>
        <li>AMENDMENTS</li>
        <li>BLACK OWL BIOCHAR PREMIUM</li>
        <li>GLACIAL ROCK DUST (TM MIX)</li>
        <li>CASCADE/GAIA GREEN BASALT DUST</li>
        <li>AZOMITE GRAN (TM MIX)</li>
        <li>CALPHOS POWDER/SOFT ROCK (TM MIX)</li>
        <li>LIVE EARTH HUMATES</li>
        <li>JERSEY GREENSAND</li>
        <li>K-MAG</li>
        <li>D EARTH</li>
        <li>WORM CASTING</li>
        <li>ENDO GRANULATED MYCORRHIZAL</li>
        <li>MINERALS</li>
        <li>LIME LAWN AND GARDEN</li>
        <li>OYSTER SHELL FLOWER</li>
        <li>DOLOMITE 10</li>
        <li>HYDRATED LIME</li>
        <li>GRANULAR GYPSUM</li>
        <li>C POTASSIUM SULPHATE FINES OG</li>
        <li>ZINC SULFATE POWDER</li>
        <li>COPPER SULFATE</li>
        <li>MANAGANESE SULFATE</li>
        <li>BORAX DECA-HYDRATE</li>
        <li>SULFUR GRANULES</li>
      </ul>

      <div class="row">
        <div class="text-center">
          <img alt="Composition tests" style={{ width: `90%`}} src={composition_test}></img>
          <img alt="Bulk storage at Baxter Barn" style={{ width: `90%`, maxWidth: `600px`, marginTop: `20px` }} src={bulk_storage}></img>
        </div>
      </div>
    </div>
  </Layout>
)

export default BulkCofCompost
